import Cookies from 'universal-cookie';

import { googleConnectionAuthApi } from '../../webservices/auth-api';

function load() {
  const interval = setInterval(() => {
    if (window.gapi) {
      window.gapi.load('auth2', init);
      clearInterval(interval);
    }
  }, 100);
}
// 1. Load the JavaScript client library.

async function init() {
  // 2. Initialize the JavaScript client library.
  await window.gapi.auth2.init({
    client_id:
      '906110026825-3mv59bett800p2cg6dtpuqvg2fes6clu.apps.googleusercontent.com',
    scope: 'profile https://www.googleapis.com/auth/user.birthday.read',
  });
}
async function googleConnect(redirectUrl, targetAppId) {
  const { code } = await window.gapi.auth2
    .getAuthInstance()
    .grantOfflineAccess({ prompt: 'select_account' });
  try {
    const response = await googleConnectionAuthApi(code, targetAppId);
    setCookies(response.data, targetAppId);
    window.location.href = redirectUrl;
  } catch (e) {
    window.location.href = `/unauthorizedUser?redirect_url=${redirectUrl}&appId=${targetAppId}`;
  }
}
function getCookieName(targetAppId) {
  const specificTokens = {
    'admin-1-jeune-1-solution': 'admin-1-jeune-1-solution_tokens',
    crm: 'wiz-auth-wizbii',
  };

  return specificTokens[targetAppId] || 'wizbii_bo';
}

function setCookies(data, targetAppId) {
  const cookies = new Cookies();
  const cookieSubDomain = getCookieDomain();
  const wizToken = JSON.stringify({
    token: data.token,
    refreshToken: data.refreshToken,
  });
  cookies.set(getCookieName(targetAppId), wizToken, {
    domain: cookieSubDomain,
  });
}

function getCookieDomain() {
  const cookieSubDomain = [
    '',
    ...document.location.hostname.split('.').slice(-2),
  ].join('.');
  return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
}

export { init, load, googleConnect };
