import { createRule } from 'validator-creator';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';

export const required = createRule(
  'required',
  val => val !== null && (typeof val !== 'string' || !isEmpty(val)),
  'Ce champ est requis'
);

export const validEmail = createRule(
  'validEmail',
  isEmail,
  'Adresse e-mail invalide'
);
