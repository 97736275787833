import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Form from '../components/form/form';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { load } from '../components/sign-in/sign-in';
import Logo from '../images/logo.png';
import Frame from './../images/Frame.svg';
import styles from './index.module.css';

function IndexPage(props) {
  load();

  return (
    <div className={styles.root}>
      <Layout>
        <Helmet>
          <script src="https://apis.google.com/js/api.js" async defer></script>
          <script
            crossOrigin
            src="https://unpkg.com/universal-cookie@3/umd/universalCookie.min.js"
          ></script>
        </Helmet>
        <SEO />
        <div className={styles.header}>
          <img
            rel="icon"
            className={styles.header__logo}
            alt="logo-sso"
            src={Logo}
          />
          <p className={styles.header__title}>Wizbii Tools</p>
          <p className={styles.header__subtitle}>Portail de connexion</p>
        </div>
        <div className={styles.form}>
          <Form location={props.location} />
        </div>
        <div className={styles.frame}>
          <img src={Frame}></img>
        </div>
        <span className={styles.rectangle}></span>
      </Layout>
    </div>
  );
}

IndexPage.propTypes = {
  location: PropTypes.object,
};

export default IndexPage;
