import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

function SEO({ description, title, children }) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaTitle = title
    ? `${title} | ${siteMetadata.title}`
    : siteMetadata.title;

  const metaDescription = description || siteMetadata.description;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />

      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />

      {children}
    </Helmet>
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default SEO;
