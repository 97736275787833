import axios from 'axios';

import { env } from '../environment';

const baseUrl = `${env.api.auth}`;

export function googleConnectionAuthApi(authCode, targetAppId) {
  return axios.post(
    `${baseUrl}/social-connect/internal/googleauth`,
    {
      authCode,
      appId: env.applicationId,
      targetAppId,
      locale: 'fr_FR',
      tags: [],
      platform: 'b2c-galaxy',
    },
    {
      headers: {
        Referer: env.siteUrl,
      },
    }
  );
}

export function getMagicLink(email, targetAppId, redirectUrl) {
  return axios.post(`${baseUrl}/v1/auth/magic-link`, {
    email,
    appId: env.applicationId,
    extra: {
      targetAppId,
      redirectUrl,
    },
  });
}
