import '@fontsource/montserrat';
import '@fontsource/montserrat/600.css';

import PropTypes from 'prop-types';
import React from 'react';

import styles from './layout.module.css';

const Layout = ({ children }) => {
  return <main className={styles.container}>{children}</main>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
