import React from 'react';

import styles from './success.module.css';

function Success() {
  return (
    <div className={styles.content}>
      <h1>Votre lien de connexion a été envoyé</h1>
      <p>Veuillez vérifier votre boîte mail et cliquer sur le lien sécurisé.</p>
    </div>
  );
}

export default Success;
